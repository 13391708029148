import * as React from "react"
import { Layout } from "../components/layout"
import { Helmet } from 'react-helmet'
import { TrackData } from '../tracks'
import { PriceBox } from "../components/price-box"

const Title = "Ian Knowles Music";
const Description = "A collection of Ian Knowles music which has been produced over the years that is freely shared with the world";

const MusicPage = () => {
  return (
    <Layout>
      <Helmet defer={false}>
        <title>Contact Ian Knowles</title>
        <meta name="description" content="Contact Ian Knowles" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={Title} />
        <meta property="og:description" content={Description} />
        <meta property="og:image" content="http://ianknowles.com/images/banners/ik-music-download.jpg" />
      </Helmet>

      <section className="dark content-section">
        <div className="container">
          <h1>Ian Knowles | My Music</h1>
          <p>I've always got bored with generic sounding music and styles seem to come and go.
          I don't think I have ever made two tracks that sound the same.
          I have always favoured musicality over monotony and energy over passivity and I think that shows in all of my freely available music.
          So download enjoy and play out if you feel compelled to.</p>        
        </div>

        <div className="container">
        {
          TrackData.map((track, i) => {
            return(
            <div className="track">
              <div>
                <h3>{track.Title}</h3>
                <strong>{track.Artist}</strong>
              </div>
              <div>
                Tags:<br /> {track.Tags.map((tag) => { return(<span className="tag">{tag}</span>) })} 
              </div>
              <div>
                Download:<br /> <a href={track.Url.WAV} className="dl">.WAV</a>
              </div>
            </div>)
          })
        }
        </div>
      </section>

      <section className="light content-section">
        <div className="container">
          <h3>Free Music</h3>
          <p>If you liked the music you can say thank you with some of your hard earned. It will be much appreciated!</p>
          <PriceBox product_code="IK_THANKS" />
        </div>
      </section>
    </Layout>
  )
}
export default MusicPage