const TrackData = [
  {
    "Title": "Axis",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Axis(OriginalMix).wav"
    },
    "Tags": [ "PROGRESSIVE", "TRANCE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Running Light",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-RunningLight(OriginalMix).wav"
    },
    "Tags": [ "PROGRESSIVE", "TRANCE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Day Two",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-DayTwo(OriginalMix).wav"
    },
    "Tags": [ "TECHNO", "DJ FRIENDLY" ]
  },
  {
    "Title": "On",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-On(OriginalMix).wav"
    },
    "Tags": [ "ELECTRO", "DJ FRIENDLY" ]
  },
  {
    "Title": "Crush",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Crush(OriginalMix).wav"
    },
    "Tags": [ "TECHNO", "DJ FRIENDLY" ]
  },
  {
    "Title": "Play With Me",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-PlayWithMe(OriginalMix).wav"
    },
    "Tags": [ "EXPERIMENTAL", "DUB-STEP" ]
  },
  {
    "Title": "Missed Call",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-MissedCall(OriginalMix).wav"
    },
    "Tags": [ "TECH TRANCE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Saharam",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Saharam(OriginalMix).wav"
    },
    "Tags": [ "HOUSE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Jumpstart",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Jumpstart(OriginalMix).wav"
    },
    "Tags": [ "HOUSE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Okko",
    "Artist": "Ian Knowles",
    "Mix": "Energized Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Okko(EnergizedMix).wav"
    },
    "Tags": [ "TRANCE", "HARD TRANCE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Okko",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Okko(OriginalMix).wav"
    },
    "Tags": [ "TRANCE", "DJ FRIENDLY" ]
  },
  {
    "Title": "XBass",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-XBass(OriginalMix).wav"
    },
    "Tags": [ "HARD STYLE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Sundown",
    "Artist": "Ian Knowles",
    "Mix": "Energized Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Sundown(EnergizedMix).wav"
    },
    "Tags": [ "PROGRESSIVE", "HARD TRANCE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Sundown",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Sundown(OriginalMix).wav"
    },
    "Tags": [ "PROGRESSIVE", "TRANCE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Kadabada",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Kadabada(OriginalMix).wav"
    },
    "Tags": [ "EXPERIMENTAL", "DUB-STEP" ]
  },
  {
    "Title": "Nostalgia",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Nostalgia(OriginalMix).wav"
    },
    "Tags": [ "HOUSE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Snap",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Snap(OriginalMix).wav"
    },
    "Tags": [ "TECHNO", "HOUSE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Ready",
    "Artist": "Ian Knowles",
    "Mix": "Energized Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Ready(EnergizedMix).wav"
    },
    "Tags": [ "HARD TRANCE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Bulgaria",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Bulgaria(OriginalMix).wav"
    },
    "Tags": [ "BREAKS", "PROGRESSIVE", "DJ FRIENDLY" ]
  },
  {
    "Title": "Bounce",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Bounce(OriginalMix).wav"
    },
    "Tags": [ "EXPERIMENTAL", "DJ FRIENDLY" ]
  },
  {
    "Title": "Flatline",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-Flatline(OriginalMix).wav"
    },
    "Tags": [ "TECHNO", "DJ FRIENDLY" ]
  },
  {
    "Title": "Body Rock",
    "Artist": "Ian Knowles",
    "Mix": "Original Mix",
    "Url": {
      "WAV": "https://ianknowles.blob.core.windows.net/music/IanKnowles-BodyRock(OriginalMix).wav"
    },
    "Tags": [ "EXPERIMENTAL", "HOUSE" ]
  }
];

export { TrackData }